import React from 'react';
import Layout from '../../layouts/index';

const Post = ({ children, pageContext }) => {
  const { date, title, image } = pageContext.frontmatter;
  return (
    <Layout bodyClass="blog">
      <div className="container">
        <div className="jumbotron mt-4 mb-3 text-white rounded bg-dark" style= {{ backgroundImage: `url(${image})` }} />

        <div className="mb-1 text-muted">{date}</div>

          {children}

        <h1 className="text-center">
          <span
            role="img"
            aria-label="Rock on!!!"
          >
            🤘🤘🤘
          </span>
        </h1>

      </div>
    </Layout>
  );
};

export default Post;
