import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
import Collage from '../../components/Collage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Demo Day`}</h1>
    <strong>
November 1st, 2019 will stay an important milestone in the history of Mbanq Labs.
    </strong>
    <p>{`On November 1st, 2019 the founders of the first Mbanq Labs batch joined to showcase their products and pitch their ideas in front of the crowd of around 70 people.`}</p>
    <p>{`After a short get together with some drinks and food Mbanq Labs team welcomed the audience with the brief presentation of the Mbanq products and mbanq strategy for the Southeast Asian market and handed the stage over to the Labs startups.`}</p>
    <p>{`All 3 local founder teams were present at the Demo Day. We're very happy that `}<a href="https://www.linkedin.com/in/mperelman/" target="_blank">{`Mark`}</a>{` from Capitao flew in from New York for the Demo Day as well.`}</p>
    <p>{`Unfortunately the guys from `}<a href="https://1marex.com/" target="_blank">{`1Marex`}</a>{` couldn't make it, so Mohan had to do a remote  pitch.`}</p>
    <p>{`It was our only hickup during the Demo Day and we decided to not let it happen ever again.`}</p>
    <p>{`All 5 of Mbanq's startups:`}</p>
    <ul>
      <li parentName="ul"><a href="https://1marex.com/" target="_blank">1Marex</a></li>
      <li parentName="ul">{`Neptune Digital`}</li>
      <li parentName="ul"><a href="https://www.swapie.co/" target="_blank">Swapie</a></li>
      <li parentName="ul">{`Smart Towkay`}</li>
      <li parentName="ul">{`Capitao`}</li>
    </ul>
    <p>{`Take a look at some pictures from the event down below.`}</p>
    <div className="d-flex justify-content-center">
  <Collage photos={props.pageContext.frontmatter.pictures} layout={[2, 2, 2]} mdxType="Collage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      